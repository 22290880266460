import React from 'react';
import Layout from '../../components/layout/Layout';
import dossier from '../../data/pratiche-patenti/conversione-patente-estera/dossier.json';
import PageNavigator from '../../components/layout/PageNavigator';
import ContentDossierPrice from '../../components/ContentDossierPrice';
import Seo from '../../components/layout/SEO';

const ConversionePatenteEstera = () => {
  return (
    <Layout>

      <Seo
        title={"Conversione Patente Estera | Autoscuola Faro, Pisa"}
        description={"Converti la tua patente di guida da estera a italiana, presso Autoscuola Faro in centro a Pisa."}
        keywords={[
          "Conversione Patente Estera italiana Pisa",
          "Conversione Patente Estera Pisa",
          "Conversione Patente Straniera in Patente Italiana Pisa"
        ]}
      />

      <PageNavigator link="/pratiche-patenti" text="Pratiche patenti" />
      <ContentDossierPrice dossier={dossier} />
    </Layout>
  );
};

export default ConversionePatenteEstera;
